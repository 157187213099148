<template>
  <v-container>
    <overlay-loader overlay :loading="initLoading"></overlay-loader>

    <v-row v-if="!initLoading" justify="center">
      <v-col cols="12" md="12" order="0">
        <v-stepper outlined vertical v-model="stepperStep">
          <!--#region Step 1 -->
          <v-stepper-step color="orange" :complete="stepperStep > 1" step="1">
            Тариф
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card class="pa-5 mb-3">
              <brand-tariffs :id="id" :provider="provider" v-model="selectedTariff"></brand-tariffs>
            </v-card>

            <v-btn @click="stepperStep++" color="orange" class="ma-2" :disabled="selectedTariff === null">
              Далее
            </v-btn>
          </v-stepper-content>
          <!--#endregion -->
          <!--#region Step 2 -->
          <v-stepper-step color="orange" :complete="stepperStep > 2" step="2">
            Данные пассажиров
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card rounded class="pt-3 px-3 mb-7">
              <v-row justify="center">
                <v-tabs class="mt-5" center-active grow color="orange" :show-arrows="true">
                  <v-tab v-for="(passenger, n) in passengers" :key="n">
                    <span>
                      {{
                        passenger.FirstName
                        ? passenger.FirstName
                        : n + 1 + " Пассажир "
                      }}
                    </span>
                  </v-tab>
                  <v-tab-item v-for="(passenger, n) in passengers" :key="n">
                    <passenger-info :countriesList="countries" :docs="docTypes" v-model="passengers[n]"
                      @updateValid="onValidUpdate($event, n)">
                    </passenger-info>
                  </v-tab-item>
                </v-tabs>
              </v-row>
              <v-form ref="contactInfo" v-model="contactInfoValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined color="orange" min="2" label="Телефон" type="phone" v-model="phone" required
                      :rules="phoneRule">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined color="orange" min="2" label="Email" type="email" v-model="email" required
                      :rules="emailRule" append-icon="mdi-email-plus" @click:append="email = $store.state.user.email">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
            <v-btn color="orange" @click="stepperStep++" class="mx-2" :disabled="!comparer">
              Далее
            </v-btn>

            <v-btn @click="stepperStep--" text class="mx-2"> К тарифам </v-btn>
          </v-stepper-content>
          <!--#endregion -->
          <!--#region Step 3 -->
          <v-stepper-step color="orange" :complete="stepperStep > 3" step="3">
            Бронирование
          </v-stepper-step>

          <v-stepper-content step="3">
            <reservation-summary v-if="selectedTariff" v-model="markup" :displayPrice="selectedTariff.price.total"
              :displayCount="passCount">
            </reservation-summary>
            <v-btn color="orange" class="mx-2" elevation="0" :loading="isLoading" :disabled='isDisabled'
              @click="Boocking">
              Бронировать
            </v-btn>
            <v-btn @click="stepperStep--" text class="mx-2">
              К пассажирам
            </v-btn>
          </v-stepper-content>
          <!--#endregion Step 3 -->
        </v-stepper>
      </v-col>
    </v-row>

    <!--show cart-->
    <div v-if="payDialog" class="text-center">
      <v-dialog persistent width="50%" v-model="payDialog">
        <purchase-summary :value="bookInfo" :ticketsCount="passCount" @cancel="(val) => (payDialog = val)" @warn="(val) => (warnDialog = val)">
        </purchase-summary>
      </v-dialog>
    </div>

    <div v-if="warnDialog" class="text-center">
      <v-dialog persistent width="50%" v-model="warnDialog">
        <warn-book-modal @cancel="(val) => (warnDialog = val)">
        </warn-book-modal>
      </v-dialog>

    </div>
  </v-container>
</template>

<script>
import ErrorModal from "@/components/UI/ErrorModal.vue";
import BrandTariffs from "@/components/BuyPage/BrandTariffList.vue";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import PassengerInfo from "@/components/BuyPage/PassengerInfo.vue";
import PurchaseSummary from "@/components/BuyPage/PurchaseSummary.vue";
import ReservationSummary from "@/components/BuyPage/ReservationSummary.vue";
import ApiCalls from "@/mixins/booking/ApiCalls.js";
import DateFormat from "@/mixins/DateFormat.js";
import Validators from "@/mixins/Validators.js";
import { VueMaskDirective } from "v-mask";
import PassengerHelper from "@/mixins/booking/PassengerHelper.js";
import WarnBookModal from "@/components/BuyPage/WarnBookModal.vue";

export default {
  name: "BuyTicket",
  directives: { mask: VueMaskDirective },
  components: {
    PassengerInfo,
    ErrorModal,
    OverlayLoader,
    BrandTariffs,
    ReservationSummary,
    PurchaseSummary,
    WarnBookModal
  },
  mixins: [DateFormat, PassengerHelper, Validators, ApiCalls],
  props: {
    adt: { type: Number, required: true },
    cnn: { type: Number, required: true },
    inf: { type: Number, required: true },
    provider: { type: String, required: true },
    id: { type: String, required: true },
  },
  data() {
    return {
      initLoading: true,
      stepperStep: 1,
      selectedTariff: null,
      contactInfoValid: false,
      validForms: [],
      bookInfo: null,
      payDialog: false,
      warnDialog: false,

      regnum: null,
      surname: null,
      docTypes: [],
      passengers: [],
      countries: [],
      isLoading: false,
      insurances: null,
      email:
        process.env.NODE_ENV === "development" ? "b.bondarev@bpo.travel" : "",
      phone: process.env.NODE_ENV === "development" ? "+79532720039" : "",
      markup: "",
    };
  },
  async mounted() {
    this.initLoading = true;
    this.countries = await this.getCountries();
    console.warn(this.adt);
    console.warn(this.cnn);
    console.warn(this.inf);
    console.error("Errrrrrrrr");

    this.passengers = this.generateModels(this.adt, this.cnn, this.inf);

    this.initLoading = false;
  },
  computed: {
    comparer() {
      return this.valid && this.contactInfoValid;
    },
    valid() {
      return this.validForms.every((e) => e);
    },
    passCount() {
      return Number(this.adt) + Number(this.cnn) + Number(this.inf);
    },
    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
    isDisabled() {
      return !this.isSubagent && !this.markup
    }
  },
  methods: {
    async Boocking() {
      this.isLoading = true;
      try {
        let req = this.BookRequest(
          this.selectedTariff.id,
          this.passengers,
          this.phone,
          this.email,
          this.provider,
          this.selectedTariff,
          this.markup
        );
        let resp = await this.$axios.post(this.$enums.Endpoints.Order.Book, req);
        this.bookInfo = resp.data;
        this.payDialog = true;
      } finally {
        this.isLoading = false;
      }
    },
    getMarkup(markup) {
      this.markup = markup
    },
    BookRequest(id, passengers, phone, email, provider, selectedTariff, markup) {

      return {
        OfferId: id,
        Passengers: passengers.map((e) => ({
          Id: e.Id,
          Birthday: new Date(e.Birthday.setHours(e.Birthday.getHours() + 4)),
          GenderCode: e.GenderCode,
          TypeCode: e.TypeCode,
          Surname: e.Surname,
          FirstName: e.FirstName,
          MiddleName: e.MiddleName,
          IdentityDoc: {
            Surname: e.Surname,
            DocNumber: e.DocNumber,
            TypeCode: e.TypeCodeDoc,
            IssueCountryCode: e.IssueCountryCode,
            ExpiryDate: e.ExpiryDate != null ? new Date(e.ExpiryDate.setHours(e.ExpiryDate.getHours() + 4)) : null
          },
        })),
        Contacts: [
          {
            EmailAddress: email,
            Phone: phone,
          },
        ],
        Provider: provider,
        ExtraJSON: JSON.stringify(selectedTariff.extra),
        Markup: markup,
      };
    },

    onValidUpdate(data, n) {
      this.validForms.splice(n, 1, data);
    },
    selectBrandTariff(brandId) {
      this.selectedTariff = brandId;
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.test {
  border: 2px solid red;
  margin: 5px;
}

.stepper {
  overflow: visible;
}

.localfix .v-slide-group__prev.v-slide-group__prev--disabled,
.localfix .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}
</style>
