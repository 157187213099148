<template>
    <div>
      <v-card>
        <div>
          <v-card-title v-if="!isSolding" class="headline accent">
            Забронировано
          </v-card-title>
  
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-row class="text-h4 font-weight-bold mt-10">Заказ забронирован и изменить данные пассажира на этой странице больше нельзя!<br />
                    Для того, чтобы исправить данные пассажира, отмените бронирование и сделайте новое!
                  </v-row>

                  <v-row justify="end" class="mt-10">
                    <v-spacer></v-spacer>
                    <v-btn dark elevation="5" :disabled="isSolding" :loading="isSolding" color="orange" large
                      @click="pushToFindTkt">
                      Ок
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </div>
      </v-card>
    </div>
  </template>
  
  <script>

  export default {

    methods: {
      cancel() {
        this.$emit("cancel", false);
      },

      async pushToFindTkt() {
            await this.$router.push("/find-tkt");
            await this.cancel();
        }
    },
  };
  </script>
  
  <style></style>
  